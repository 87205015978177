import React, {useState} from 'react';
import axios from 'axios';
import {Line} from 'react-chartjs-2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Chart from 'chart.js/auto'; //without this we are dead

function App() {
    console.log("chart version: " + Chart.version); //so import is not removed

    const [selectedService, setSelectedService] = useState('getIpc');
    const [startYear, setStartYear] = useState('2010');
    const [startMonth, setStartMonth] = useState('01');
    const [endYear, setEndYear] = useState('2023');
    const [endMonth, setEndMonth] = useState('12');
    const [data, setData] = useState([]);

    const fetchServiceData = async () => {
        try {
            const response = await axios.get(
                `https://api.sueldapp.leoae.duckdns.org/${selectedService}?from=${startYear}-${startMonth}&to=${endYear}-${endMonth}`
            );

            // Check if the selected service is getUsdArsB
            if (selectedService === 'getUsdArsB') {
                // For getUsdArsB service, the data is already in the correct format (date and value)
                setData(response.data);
            } else {
                // For other services, multiply the values by 100
                setData(response.data.map(entry => ({date: entry.date, amount: entry.amount * 100})));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const chartData = {
        labels: data.map((entry) => entry.date),
        datasets: [
            {
                label: `${selectedService} Data`,
                data: data.map((entry) => selectedService === 'getUsdArsB' ? entry.amount : entry.amount * 100),
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 2,
            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    const yearOptions = Array.from({length: 15}, (_, index) => (2010 + index).toString());
    const monthOptions = Array.from({length: 12}, (_, index) => (index + 1).toString().padStart(2, '0'));

    return (
        <div className="container">
            <h1 className="mt-3">SueldApp</h1>

            <div className="input-container mt-3">
                <label>Select Service:</label>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className={`btn btn-primary ${selectedService === 'getIpc' ? 'active' : ''}`}>
                        <input
                            type="radio"
                            id="getIpc"
                            value="getIpc"
                            checked={selectedService === 'getIpc'}
                            onChange={() => setSelectedService('getIpc')}
                        />
                        Inflation
                    </label>
                    <label className={`btn btn-primary mx-2 ${selectedService === 'getCer' ? 'active' : ''}`}>
                        <input
                            type="radio"
                            id="getCer"
                            value="getCer"
                            checked={selectedService === 'getCer'}
                            onChange={() => setSelectedService('getCer')}
                        />
                        CER Index
                    </label>
                    <label className={`btn btn-primary ${selectedService === 'getUsdArsB' ? 'active' : ''}`}>
                        <input
                            type="radio"
                            id="getUsdArsB"
                            value="getUsdArsB"
                            checked={selectedService === 'getUsdArsB'}
                            onChange={() => setSelectedService('getUsdArsB')}
                        />
                        USD to ARS Exchange Rate
                    </label>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-6">
                    <label>Select Start Date:</label>
                    <div className="input-group">
                        <select
                            className="form-control"
                            value={startYear}
                            onChange={(e) => setStartYear(e.target.value)}
                        >
                            {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        <select
                            className="form-control"
                            value={startMonth}
                            onChange={(e) => setStartMonth(e.target.value)}
                        >
                            {monthOptions.map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="col-md-6">
                    <label>Select End Date:</label>
                    <div className="input-group">
                        <select
                            className="form-control"
                            value={endYear}
                            onChange={(e) => setEndYear(e.target.value)}
                        >
                            {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        <select
                            className="form-control"
                            value={endMonth}
                            onChange={(e) => setEndMonth(e.target.value)}
                        >
                            {monthOptions.map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <button className="btn btn-primary mt-3" onClick={fetchServiceData}>
                Get Data
            </button>

            {data.length > 0 && (
                <div className="chart-container mt-3">
                    <Line data={chartData} options={chartOptions}/>
                </div>
            )}
        </div>
    );
}

export default App;
